import React from "react"

import { navigate } from "gatsby"
import { isBrowser } from "../components/helpers/helpers"


const caseStudyPage = () => {
  
  if (isBrowser()) navigate('/case-studies/');

  return (
    <>
    </>
  )
};

export default caseStudyPage;